import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

const Button = (props) => {
    return (
        <button
            disabled={props.disabled}
            className={[styles.Button, styles[props.buttonType]].join(' ')}
            onClick={props.clickHandler}
        >
            {props.children}
        </button>
    );
}

Button.propTypes = {
    buttonType: PropTypes.string.isRequired,
    clickHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default Button;
