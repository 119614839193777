import { updateObject } from '../../ObjectTools';
import * as Constants from '../../Constants';

export const getImageStyle = (settings) => {
    // Image-Settings
    let imgStyle = {};
    switch (settings.type) {
        case Constants.IMGTYPE_BACKGROUND:
            if (settings.background) {
                const bg = settings.background;
                if (bg.origin) {
                    imgStyle = updateObject(imgStyle, getPosition(bg.origin));
                }
            }
            break;
        default:
            console.log('invalid settings-type');
    }
    return imgStyle;
}

// const getFloat = (float) => {
//     switch (float) {
//         case Constants.LOCATION_LEFT:
//             return { float: 'left', clear: 'right' };
//         case Constants.LOCATION_RIGHT:
//             return { float: 'right', clear: 'left' };
//         default:
//             return null;
//     }
// }

const getPosition = (pos) => {
    switch (pos) {
        case Constants.LOCATION_TOP_LEFT:
            return { backgroundPosition: 'top left' };
        case Constants.LOCATION_TOP:
            return { backgroundPosition: 'top' };
        case Constants.LOCATION_TOP_RIGHT:
            return { backgroundPosition: 'top right' };
        case Constants.LOCATION_LEFT:
            return { backgroundPosition: 'left' };
        case Constants.LOCATION_CENTER:
            return { backgroundPosition: 'center' };
        case Constants.LOCATION_RIGHT:
            return { backgroundPosition: 'right' };
        case Constants.LOCATION_BOTTOM_LEFT:
            return { backgroundPosition: 'bottom left' };
        case Constants.LOCATION_BOTTOM:
            return { backgroundPosition: 'bottom' };
        case Constants.LOCATION_BOTTOM_RIGHT:
            return { backgroundPosition: 'bottom right' };
        default:
            return null;
    }
}