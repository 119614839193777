import React from 'react';
import * as styles from './Construction.module.css';
import hook from '../../images/construction/hook.png';
import cardEdge from '../../images/construction/card_edge.png';

const Construction = () => {
    return (
        <div className={styles.container}>
            <img src={hook} alt="crane_hook" className={styles.hook} />
            <div className={styles.card}>
                <div className={styles.card_edge} style={{backgroundImage: `url(${cardEdge})`}} />
                <div className={styles.content}>
                    This page is currently under construction
                    <br />
                    <br />
                    Please come back later
                </div>
                <div className={[styles.card_edge, styles.mirror].join(' ')} style={{backgroundImage: `url(${cardEdge})`}} />
            </div>
        </div>
    );
}

export default Construction;