import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavigationItems from '../NavigationItems/NavigationItems';
import HamburgerButton from '../../UI/Buttons/HamburgerButton/HamburgerButton';
import styles from './Toolbar.module.css';
import cssUtils from '../../../utils/css/responsive.module.css';
import logoLarge from '../../../images/logo/Logo_240.png';
import logoInverted from '../../../images/logo/Logo_inverted_240.png';

// On Mobile: Show DrawerButton & Logo
// On Tablet: Show DrawerButton, Logo & Navigation
// On Desktop: Show Logo & Navigation
const Toolbar = (props) => {
    return (
        <header className={[cssUtils.toolbarHeight, styles.toolbar].join(' ')}>
            {/* DrawerButton */}
            <nav className={[cssUtils.notOnDesktop, styles.menuButton].join(' ')}>
                <HamburgerButton color={'white'} clickHandler={props.drawerHandler} />
            </nav>
            {/* Logo */}
            <nav className={styles.logo} onClick={() => props.history.push('/home')}>
                <img className={styles.logoNormal} src={logoLarge} alt={'Frank van Hoof'} />
                <img className={styles.logoHover} src={logoInverted} alt={'Frank van Hoof'} />
            </nav>
            {/* Navigation */}
            <nav className={[cssUtils.notOnMobile, styles.navigation].join(' ')}>
                <NavigationItems
                    containerStyling={'row'}
                    useHoverMenu={true}
                    itemStyling={'row'}
                    textColor={'white'}
                    defaultDrawChildItems={false}
                />
            </nav>
        </header>
    );
}

Toolbar.propTypes = {
    drawerHandler: PropTypes.func.isRequired
}

export default withRouter(Toolbar);