import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './NavigationItem.module.css';

const NavigationItem = React.memo((props) => {
    const classes = [styles.navigationItem];
    if (props.classes) {
        for (let key of props.classes) {
            classes.push(styles[key]);
        }
    }
    return (
        <NavLink className={classes.join(' ')}
            to={props.link}
            exact={props.exact}
            activeClassName={styles.active}
            style={{ color: props.textColor ? props.textColor : 'black' }}
            onClick={props.onLinkHandler}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            {props.children}
        </NavLink>
    );
});

NavigationItem.propTypes = {
    link: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    onLinkHandler: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    exact: PropTypes.bool,
    classes: PropTypes.array
}

export default NavigationItem;