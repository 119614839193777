import * as actionTypes from './actionTypes';
import axios from '../../axios-api';

export const fetchPage = (id) => {
    return dispatch => {
        dispatch(fetchInit());
        axios.get('/pages/'+id)
            .then(response => {
                dispatch(storePage(id, response.data));
            })
            .catch(error => {
                dispatch(fetchFailed(error));
            });
    };
}

const fetchInit = () => {
    return {
        type: actionTypes.FETCH_PAGE_INIT
    };
}

const storePage = (id, page) => {
    return {
        type: actionTypes.STORE_PAGE,
        pageName: id,
        content: page
    };
}

const fetchFailed = (error) => {
    return {
        type: actionTypes.FETCH_PAGE_FAILED,
        error: error
    };
}