import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import NavigationItems from '../NavigationItems/NavigationItems';
import Background from '../../UI/Background/Background';
import * as styles from './SideDrawer.module.css';

const animationTiming = {
    enter: 400,
    exit: 300
};

const SideDrawer = React.memo((props) => {
    return (
        <CSSTransition
            in={props.isOpen}
            timeout={animationTiming}
            mountOnEnter
            unmountOnExit
            classNames={{
                enterActive: styles.open,
                exitActive: styles.close
            }}
        >
            <div>
                <Background type={'Underlay'} classNames={styles.background} clickHandler={props.closeHandler} />
                <div className={styles.drawer}>
                    <nav className={styles.items}>
                        <NavigationItems
                            prepend={{ Home: '/home' }}
                            containerStyling={'column'}
                            useHoverMenu={false}
                            itemStyling={'column'}
                            textColor={'black'}
                            defaultDrawChildItems={true}
                            onLinkHandler={props.closeHandler}
                        />
                    </nav>
                </div>
            </div>
        </CSSTransition>
    );
});

SideDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeHandler: PropTypes.func.isRequired
};

export default SideDrawer;