import React from 'react';
import PropTypes from 'prop-types';
import styles from './Background.module.css';

const Background = (props) => {
    let style = null;
    switch (props.type){
        case 'Underlay':
            style = styles.Underlay;
            break;
        default:
            throw new Error('No Background of that Type has been configured yet');
    }
    return <div className={[style, props.classNames].join(' ')} onClick={props.clickHandler} />;
}

Background.propTypes = {
    type: PropTypes.string.isRequired,
    classNames: PropTypes.string,
    clickHandler: PropTypes.func
}

export default Background;