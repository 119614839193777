import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/ObjectTools';

const initialState = {
    loading: false,
    error: null,
    projects: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_PROJECT:
            return storeProject(state, action);
        case actionTypes.FETCH_PROJECT_INIT:
            return updateObject(state, { loading: true });
        case actionTypes.FETCH_PROJECT_FAILED:
            return updateObject(state, { error: action.error, loading: false });
        default:
            return state;
    }
}

const storeProject = (state, action) => {
    const project = { [action.id]: action.content };
    const updatedList = updateObject(state.projects, project);
    return updateObject(state, {
        loading: false,
        error: null,
        projects: updatedList
    });
}

export default reducer;