import * as actionTypes from './actionTypes';
import axios from '../../axios-api';

export const fetchProject = (id) => {
    return dispatch => {
        dispatch(fetchInit());
        axios.get('/project/' + id)
            .then(response => {
                dispatch(storeList(id, response.data));
            })
            .catch(error => {
                dispatch(fetchFailed(error));
            });
    };
}

const fetchInit = () => {
    return {
        type: actionTypes.FETCH_PROJECT_INIT
    };
}

const storeList = (id, project) => {
    return {
        type: actionTypes.STORE_PROJECT,
        id: id,
        content: project
    };
}

const fetchFailed = (error) => {
    return {
        type: actionTypes.FETCH_PROJECT_FAILED,
        error: error
    };
}