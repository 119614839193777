import React from 'react';
import Layout from './hoc/Layout/Layout';
import { Route, Switch, withRouter } from 'react-router';
import { asyncLanding, asyncHome, asyncProjectPage, asyncContact, asyncErrorPage } from './utils/lazyLoad/lazyLoad';
import Construction from './components/Construction/Construction';
import { Helmet } from 'react-helmet';
import ProjectLists from './containers/Projects/ProjectLists';

function App() {
  return (

    <Switch>
      <Route path={'/'} exact component={asyncLanding} />
      <Layout>
        <Switch>
          <Route path={'/home'} exact component={asyncHome} />
          <Route path={'/project/:id'} component={asyncProjectPage} />
          <Route path={'/projects'} exact component={ProjectLists} />
          <Route path={'/projects/:category'} exact component={ProjectLists} />
          <Route path={'/about'} exact component={() => <React.Fragment><Helmet><title>About | Frank van Hoof</title></Helmet><Construction /></React.Fragment>} />
          <Route path={'/contact'} exact component={asyncContact} />
          <Route path={'/404'} exact component={asyncErrorPage} />
          <Route path="*" component={asyncErrorPage} />
        </Switch>
      </Layout>
    </Switch>
  );
}

export default withRouter(App);
