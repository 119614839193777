import * as actionTypes from './actionTypes';
import axios from '../../axios-api';

export const fetchList = (id) => {
    return dispatch => {
        dispatch(fetchInit());
        axios.get('/projects/'+id)
            .then(response => {
                dispatch(storeList(id, response.data));
            })
            .catch(error => {
                dispatch(fetchFailed(error));
            });
    };
}

const fetchInit = () => {
    return {
        type: actionTypes.FECTH_LIST_INIT
    };
}

const storeList = (id, list) => {
    return {
        type: actionTypes.STORE_LIST,
        listId: id,
        content: list
    };
}

const fetchFailed = (error) => {
    return {
        type: actionTypes.FECTH_LIST_FAILED,
        error: error
    };
}