import React from 'react';
import PropTypes from 'prop-types';
import NavigationItem from '../NavigationItems/NavigationItem/NavigationItem';
import { CSSTransition } from 'react-transition-group';
import * as styles from './SubMenu.module.css';
import * as cssUtils from '../../../utils/css/responsive.module.css';

const animationTiming = {
    enter: 300,
    exit: 200
}

const SubMenu = (props) => {
    const navItems = [];
    for (let item of props.items) {
        for (let key in item) {
            navItems.push(
                <NavigationItem key={key} exact={true} link={item[key]} textColor={props.textColor} onLinkHandler={props.onLinkHandler} classes={props.classes}>
                    {key}
                </NavigationItem>
            );
        }
    }
    const listClasses = [styles.submenuContainer];
    if (props.positionAbsolute) {
        listClasses.push(styles.absolute);
    }
    if (props.notOnTouchScreen) {
        listClasses.push(cssUtils.noTouch);
    }
    return (
        <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={animationTiming}
            classNames={{
                enter: styles.openInit,
                exit: styles.closeInit,
                enterActive: styles.open,
                exitActive: styles.close
            }}
        >
            <ul className={listClasses.join(' ')} style={{ backgroundColor: props.bgColor }}>
                {navItems}
            </ul >
        </CSSTransition>
    )
}

SubMenu.propTypes = {
    show: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    height: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    positionAbsolute: PropTypes.bool.isRequired,
    notOnTouchScreen: PropTypes.bool.isRequired,
    bgColor: PropTypes.string,
    onLinkHandler: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    exact: PropTypes.bool,
    classes: PropTypes.array
}

export default SubMenu;