import React from 'react';
import PropTypes from 'prop-types';
import styles from './HamburgerButton.module.css';

const HamburgerButton = (props) => {
    return (
        <div className={styles.hamburgerButton} onClick={props.clickHandler}>
            <div style={{backgroundColor: props.color}} />
            <div style={{backgroundColor: props.color}} />
            <div style={{backgroundColor: props.color}} />
        </div>
    )
}

HamburgerButton.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired
};

export default HamburgerButton;