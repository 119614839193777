import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NavigationItem from './NavigationItem/NavigationItem';
import SubMenu from '../SubMenu/SubMenu';
import { updateObject } from '../../../utils/ObjectTools';
import * as styles from './NavigationItems.module.css';

const items = {
    Projects: {
        link: '/projects',
        subItems: [
            { 'Game Development': '/projects/gamedev' },
            { 'Software Development': '/projects/software' },
            { Tools: '/projects/tools' }
        ]
    },
    About: '/about',
    Contact: '/contact'
}

const initialMenuState = {};

const NavigationItems = React.memo((props) => {
    const [subMenuState, setSubMenuState] = useState(initialMenuState);
    let navItems = [];
    const finalItems = { ...props.prepend, ...items, ...props.append }
    for (let key in finalItems) {
        const value = finalItems[key];
        // Treat as object. Look for sub-Items and create Top-Link
        if (typeof value === 'object') {
                const handleHover = (key, newVal) => {
                    if (subMenuState[key] !== newVal) {
                        setSubMenuState(updateObject(subMenuState, { [key]: newVal }));
                    }
                }
                navItems.push(
                    <div key={key} onMouseLeave={() => handleHover(key, false)} className={props.containerStyling}>
                        {/* Top-Item */} 
                        <NavigationItem onMouseEnter={() => handleHover(key, true)} link={value.link} textColor={props.textColor} onLinkHandler={() => { handleHover(key, false); if (props.onLinkHandler) { props.onLinkHandler(); } }} classes={[props.itemStyling, props.containerStyling === 'row' ? 'backgroundToolbar' : 'column']}>
                            {key}
                        </NavigationItem>
                        
                        {/* Menu */}
                        <SubMenu
                            classes={['column']}
                            bgColor={props.containerStyling === 'row' ? '#36393F' : 'white'}
                            show={props.defaultDrawChildItems ? true : (subMenuState[key] ?? false)}
                            items={value.subItems}
                            height={'150px'}
                            textColor={props.textColor}
                            notOnTouchScreen={!props.useHoverMenu && !props.defaultDrawChildItems}
                            positionAbsolute={!props.defaultDrawChildItems}
                            onLinkHandler={() => {handleHover(key, false); if (props.onLinkHandler) {props.onLinkHandler();}}}
                        />
                    </div>);
        } else if (typeof value === 'string') {
            navItems.push(
                    <NavigationItem key={key} exact link={value} textColor={props.textColor} onLinkHandler={props.onLinkHandler} classes={[props.itemStyling, props.containerStyling === 'row' ? 'backgroundToolbar' : 'column']}>
                        {key}
                    </NavigationItem>);
        } else {
            throw new Error('Invalid Item-Type');
        }
    }
    const styleClasses = [styles[props.containerStyling], styles.navigationItems].join(' ');
    return (
        <ul className={styleClasses} style={{ flexFlow: props.flexDirection }}>
            {navItems}
        </ul>
    );
});

NavigationItems.propTypes = {
    textColor: PropTypes.string.isRequired,
    defaultDrawChildItems: PropTypes.bool.isRequired,
    useHoverMenu: PropTypes.bool.isRequired,
    containerStyling: PropTypes.string,
    itemStyling: PropTypes.string,
    onLinkHandler: PropTypes.func,
    prepend: PropTypes.object,
    append: PropTypes.object
}

export default NavigationItems;