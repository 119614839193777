import React, { Component } from 'react';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import * as styles from './Layout.module.css';
import * as cssUtils from '../../utils/css/responsive.module.css';

class Layout extends Component {

    state = {
        drawerOpen: false
    };

    toggleDrawerHandler = () => {
        this.setState({ drawerOpen: !this.state.drawerOpen });
    }

    closeDrawerHandler = () => {
        if (this.state.drawerOpen) {
            this.toggleDrawerHandler();
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <Toolbar drawerHandler={this.toggleDrawerHandler} />
                <SideDrawer isOpen={this.state.drawerOpen} closeHandler={this.closeDrawerHandler}/>

                <main className={[cssUtils.contentHeight, styles.content].join(' ')}>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

export default Layout;