export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_PAGE_INIT = 'FETCH_PAGE_INIT';
export const FETCH_PAGE_FAILED = 'FETCH_PAGE_FAILED';
export const STORE_PAGE = 'STORE_PAGE';

export const FECTH_LIST = 'FETCH_LIST';
export const FECTH_LIST_INIT = 'FECTH_LIST_INIT';
export const FECTH_LIST_FAILED = 'FECTH_LIST_FAILED';
export const STORE_LIST = 'STORE_LIST';

export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_PROJECT_INIT = 'FETCH_PROJECT_INIT';
export const FETCH_PROJECT_FAILED = 'FETCH_PROJECT_FAILED';
export const STORE_PROJECT = 'STORE_PROJECT';