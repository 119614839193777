import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Background from '../Background/Background';
import styles from './ErrorModal.module.css';
import Button from '../Buttons/Button';

class ErrorModal extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <React.Fragment>
                <Background type={'Underlay'} clickHandler={this.props.closeHandler} />
                <div className={styles.Modal}>
                    <div className={styles.Title}>
                        {this.props.title}
                    </div>
                    <div className={styles.Content}>
                        <div className={styles.ErrorMessage}>
                            {this.props.error.message}
                        </div>
                        <div className={styles.SubText}>
                            If the issue persists, please <a href='mailto:frank_van_hoof@hotmail.com'>contact me</a>.
                            <br />
                            <Button
                                clickHandler={this.props.closeHandler}
                                buttonType={'Neutral'}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ErrorModal.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    closeHandler: PropTypes.func.isRequired,
    error: PropTypes.object
}

export default ErrorModal;