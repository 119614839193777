import asyncComponent from '../../hoc/asyncComponent/asyncComponent';

export const asyncLanding = asyncComponent(() => {
    return import('../../containers/Landing/Landing');
});

export const asyncHome = asyncComponent(() => {
    return import('../../containers/Home/Home');
});

export const asyncProjectList = asyncComponent(() => {
    return import('../../containers/Projects/ProjectLists');
});

export const asyncProjectPage = asyncComponent(() => {
    return import('../../containers/Projects/ProjectPage/ProjectPage');
});

export const asyncContact = asyncComponent(() => {
    return import('../../containers/Contact/Contact');
});

export const asyncErrorPage = asyncComponent(() => {
    return import('../../containers/ErrorPage/ErrorPage');
});