import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/ObjectTools';

const initialState = {
    loading: false,
    error: null,
    pageData: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_PAGE:
            return storePage(state, action);
        case actionTypes.FETCH_PAGE_INIT:
            return updateObject(state, { loading: true });
        case actionTypes.FETCH_PAGE_FAILED:
            return updateObject(state, { error: action.error, loading: false });
        default:
            return state;
    }
}

const storePage = (state, action) => {
    const page = { [action.pageName]: action.content };
    const updatedPages = updateObject(state.pageData, page);
    return updateObject(state, {
        loading: false,
        error: null,
        pageData: updatedPages
    });
}

export default reducer;