import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/ObjectTools';

const initialState = {
    loading: false,
    error: null,
    listData: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_LIST:
            return storeList(state, action);
        case actionTypes.FECTH_LIST_INIT:
            return updateObject(state, { loading: true, error: null });
        case actionTypes.FECTH_LIST_FAILED:
            return updateObject(state, { error: action.error, loading: false });
        default:
            return state;
    }
}

const storeList = (state, action) => {
    const list = { [action.listId]: action.content };
    const updatedList = updateObject(state.listData, list);
    return updateObject(state, {
        loading: false,
        error: null,
        listData: updatedList
    });
}

export default reducer;