import React, { Component } from 'react';
import ErrorModal from '../../components/UI/ErrorModal/ErrorModal';

const withErrorHandler = (WrappedComponent, axios) => {
    return class ErrorHandler extends Component {
        state = {
            error: null
        }

        constructor(props) {
            super(props);
            this.reqInterceptor = axios.interceptors.request.use(request => {
                this.setState({ error: null });
                return request;
            });
            this.resInterceptor = axios.interceptors.response.use(res => res, error => {
                this.setState({ error: error });
            });
        }

        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.response.eject(this.resInterceptor);
        }

        closeHandler = () => {
            this.setState({ error: null });
        }

        render() {
            const err = this.state.error;
            let show = false;
            if ((err && !err.response) || (err && err.response && err.response.status !== 404)) {
                show = true;
            }
            return (<React.Fragment>
                <ErrorModal
                    show={show}
                    title={'An Error Occurred'}
                    closeHandler={this.closeHandler}
                    error={err}
                />
                <WrappedComponent {...this.props} />
            </React.Fragment>);
        }
    }
}

export default withErrorHandler;