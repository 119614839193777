import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProjectCard.module.css';
import { withRouter } from 'react-router';
import * as constants from '../../../utils/Constants';
import { getImageStyle } from '../../../utils/css/DynamicCss/ImageStyle';
import { updateObject } from '../../../utils/ObjectTools';
import android from '../../../images/projects/badge_android.png';
import xna from '../../../images/projects/badge_xna.png';
import ios from '../../../images/projects/badge_ios.png';
import windows from '../../../images/projects/badge_windows.png';
import web from '../../../images/projects/badge_web.png';
import ar from '../../../images/projects/badge_ar.png';
import vr from '../../../images/projects/badge_vr.png';
import motion from '../../../images/projects/badge_motion.png';
import unity from '../../../images/projects/badge_unity.png';
import multiplayer from '../../../images/projects/badge_multiplayer.png';

const ProjectCard = (props) => {
    const handleClick = () => {
        props.history.push(`/project/${props.project.id}`);
    }
    const date = Date.parse(props.project.projectInfo.delivery);
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let imgStyle = { backgroundImage: `url('${constants.IMAGE_PREFIX + props.project.thumbnail.url}')` };
    if (props.project.thumbnail.settings) {
        imgStyle = updateObject(imgStyle, getImageStyle(props.project.thumbnail.settings));
    }
    imgStyle = updateObject(imgStyle, {
        animationDelay: (props.animationDelay ?? 0) + 's'
    });
    let badges = null;
    if (props.project.projectInfo.badges) {
        let parsedBadges = [];
        const badgeArray = props.project.projectInfo.badges;
        for (let i = 0; i < badgeArray.length; i++) {
            let img = null;
            switch (badgeArray[i]) {
                case "android":
                    img = android;
                    break;
                case "ios":
                    img = ios;
                    break;
                case "windows":
                    img = windows;
                    break;
                case "web":
                    img = web;
                    break;
                case "ar":
                    img = ar;
                    break;
                case "vr":
                    img = vr;
                    break;
                case "motion":
                    img = motion;
                    break;
                case "unity":
                    img = unity;
                    break;
                case "multiplayer":
                    img = multiplayer;
                    break;
                case "xna":
                    img = xna;
                default:
                    console.log("Could not Parse badge: " + badgeArray[i]);
                    break;
            }
            if (img !== null) {
                parsedBadges.push(
                    <div key={badgeArray[i]} className={styles.badge}>
                        <img src={img} alt={badgeArray[i]} />
                    </div>
                );
            }
        }
        badges = (
            <div className={styles.badges}>
                {parsedBadges}
            </div>
        );
    }

    return (
        <div className={styles.card} onClick={handleClick} style={imgStyle}>
            {badges}
            <div className={styles.delivery}>
                {month}-{year}
            </div>
            <div className={styles.title}>
                {props.project.title.value}
            </div>
        </div>
    )
}

ProjectCard.propTypes = {
    project: PropTypes.object.isRequired,
    animationDelay: PropTypes.number
};

export default withRouter(ProjectCard);