import React, { Component } from 'react';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import * as styles from './ProjectLists.module.css';
import { Link, Redirect } from 'react-router-dom';
import Spinner from '../../components/UI/Loading/Spinner/Spinner';
import ProjectCard from '../../components/Projects/ProjectCard/ProjectCard';
import { Helmet } from 'react-helmet';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import bgGameDev from '../../images/projects/gamedev_button.png';
import bgSoftware from '../../images/projects/software_button.png';
import bgTools from '../../images/projects/tools_button.png';
import axios from '../../axios-api';

class ProjectLists extends Component {
    state = {
        category: ''
    }

    componentDidMount() {
        const category = this.props.match.params.category ?? 'gamedev';
        this.setState({ category: category });
        this.props.onFetchList(category);
    }

    componentDidUpdate() {
        const category = this.props.match.params.category ?? 'gamedev';
        if (category !== this.state.category) {
            this.props.onFetchList(category);
            this.setState({ category: category });
        }
    }

    render() {
        if (!this.props.match.params.category) {
            return <Redirect to="/projects/gamedev" />
        }
        const category = this.props.match.params.category ?? 'gamedev';
        const categoryUpper = category.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
        let content = null;
        // Show Projects
        if (this.props.projects[category] && this.props.projects[category].length > 0) {
            let cards = [];
            var list = this.props.projects[category];
            let i = 1;
            for (let obj of list) {
                cards.push(
                    <ProjectCard key={obj.id} project={obj} animationDelay={i * 0.2} />
                );
                i++;
            }
            content = <div className={styles.cards}>{cards}</div>
        }
        // Show Loading
        else if (this.props.loading) {
            content = <div className={styles.center}><Spinner /></div>;
        }
        // Show Error
        else {
            if (this.props.error) {
                content = <div className={[styles.center, styles.error].join(' ')}>An Error occurred when loading {categoryUpper}</div>;
                if (this.props.error.response) {
                    const status = this.props.error.response.status;
                    if (status === 404) {
                        content = <div className={[styles.center, styles.error].join(' ')}>Could not find Projects for {categoryUpper}</div>
                    }
                }
            }
            else {
                content = <div className={[styles.center, styles.error].join(' ')}>No Content (Yet)</div>;
            }
        }
        return (
            <React.Fragment>
                <Helmet>
                    <title>Projects | Frank van Hoof</title>
                </Helmet>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <Link to="/projects/gamedev" style={{ backgroundImage: `url(${bgGameDev})` }} className={[styles.projectType, category === 'gamedev' ? styles.active : null].join(' ')}>
                            Game{'\n'}Development
                        </Link>
                        <Link to="/projects/software" style={{ backgroundImage: `url(${bgSoftware})` }} className={[styles.projectType, category === 'software' ? styles.active : null].join(' ')}>
                            Software{'\n'}Development
                        </Link>
                        <Link to="/projects/tools" style={{ backgroundImage: `url(${bgTools})` }} className={[styles.projectType, category === 'tools' ? styles.active : null].join(' ')}>
                            Tools
                        </Link>
                    </div>
                    <div className={styles.projectContainer}>
                        {content}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.projectLists.loading,
        projects: state.projectLists.listData,
        error: state.projectLists.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchList: (id) => dispatch(actions.fetchList(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(ProjectLists, axios));