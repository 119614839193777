export const IMAGE_PREFIX = "/images/";

export const LOCATION_UNDEFINED = "UNDEFINED";
export const LOCATION_TOP_LEFT = "TopLeft";
export const LOCATION_TOP = "Top";
export const LOCATION_TOP_RIGHT = "TopRight";
export const LOCATION_LEFT = "Left";
export const LOCATION_CENTER = "Center";
export const LOCATION_RIGHT = "Right";
export const LOCATION_BOTTOM_LEFT = "BottomLeft";
export const LOCATION_BOTTOM = "Bottom";
export const LOCATION_BOTTOM_RIGHT = "BottomRight";

export const ALIGNMENT_UNDEFINED = "UNDEFINED";
export const ALIGNMENT_LEFT = "Left";
export const ALIGNMENT_CENTER = "Center";
export const ALIGNMENT_RIGHT = "Right";
export const ALIGNMENT_JUSTIFY = "Justify";

export const IMGTYPE_UNDEFINED = "UNDEFINED";
export const IMGTYPE_INLINE = "INLINE";
export const IMGTYPE_BLOCK = "INLINE_BLOCK";
export const IMGTYPE_BACKGROUND = "BACKGROUND";